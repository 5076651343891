// @flow
import React from 'react';
import { facebookPixelId, } from './InitPixel';

export default function InitPixelFallbackImg({ site, }: { site: 'htz' | 'tm' | 'hdc', }) {
  const id = facebookPixelId[site];
  return (
    <img
      alt=""
      aria-hidden="true"
      height="1"
      width="1"
      style={{ display: 'none', }}
      src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
    />
  );
}
