/* eslint-disable */
import React from 'react';
import { appendScript } from '../../utils/scriptTools';

const getInnerHtml = siteId => `
(function(o) {
  var w=window.top,a='apdAdmin',ft=w.document.getElementsByTagName('head')[0],
  l=w.location.href,d=w.document;w.apd_options=o;
  if(l.indexOf('disable_fi')!=-1) { console.error("disable_fi has been detected in URL. FI functionality is disabled for this page view."); return; }
  var fiab=d.createElement('script'); fiab.type = 'text/javascript';
  fiab.src=o.scheme+'ecdn.analysis.fi/static/js/fab.js';fiab.id='fi-'+o.websiteId;
  ft.appendChild(fiab, ft); if(l.indexOf(a)!=-1) w.localStorage[a]=1;
  var aM = w.localStorage[a]==1, fi=d.createElement('script');
  fi.type='text/javascript'; fi.async=true; if(aM) fi['data-cfasync']='false';
  fi.src=o.scheme+(aM?'cdn':'ecdn') + '.firstimpression.io/' + (aM ? 'fi.js?id='+o.websiteId : 'fi_client.js');
  ft.appendChild(fi);
})({
  'websiteId': ${siteId},
  'scheme':    '//'
});
`;

type Props = {
  siteId: string,
};

FirstImpression.defaultProps = {
  siteId: '5339',
};

export default function FirstImpression({ siteId }: Props) {
  React.useEffect(() => {
    appendScript({
      id: 'firstImpression',
      innerHtml: getInnerHtml(siteId),
      attributes: { type: 'text/javascript', 'data-cfasync': 'false' },
    });
  }, []);
  return null;
}
