import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Scripts from '../Scripts/Scripts';

import UserDispenser from '../User/UserDispenser';
import NoSSR from '../NoSSR/NoSSR';
import Footer from './Footer';
import IconHaaretzComFull from '../Icon/icons/IconHaaretzComFull';

// ///////////////////////////////////////////////////////////////////

import FirstImpression from '../Scripts/FirstImpression';

import InitPixelFallbackImg from '../Scripts/InitPixelFallbackImg';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import useIsBot from '../../hooks/useIsBot';


const AccessListByIp = dynamic(() => import('../Scripts/AccessListByIp'), {
  ssr: false,
  loading: () => null,
});

const OutbrainObct = dynamic(() => import('../Scripts/OutbrainObct'), {
  ssr: false,
  loading: () => null,
});
const Outbrain30Sec = dynamic(() => import('../Scripts/OutbrainObct').then(mod => mod.Outbrain30Sec), {
  ssr: false,
  loading: () => null,
});
const ChartBeat = dynamic(() => import('../Scripts/ChartBeat'), {
  ssr: false,
  loading: () => null,
});
const PushWoosh = dynamic(() => import('../Scripts/PushWoosh/PushWoosh'), {
  ssr: false,
  loading: () => null,
});
const FacebookPixel = dynamic(() => import('../Scripts/FacebookPixel'), {
  ssr: false,
  loading: () => null,
});
const TwitterPixel = dynamic(() => import('../Scripts/TwitterPixel'), {
  ssr: false,
  loading: () => null,
});
const OutbrainPixel = dynamic(() => import('../Scripts/OutbrainPixel'), {
  ssr: false,
  loading: () => null,
});
const OutbrainPixelContent = dynamic(() => import('../Scripts/OutbrainPixelContent'), {
  ssr: false,
  loading: () => null,
});
const GoogleAdsPixel = dynamic(() => import('../Scripts/GoogleAdsPixel'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagsManager = dynamic(() => import('../Scripts/GoogleTagsManager'), {
  ssr: false,
  loading: () => null,
});
// TODO: remove after prod check, meanwhile all pages except wordle section page should have basic configuration
// const GaEvents = dynamic(() => import('../Scripts/GaEvents'), {
//   ssr: false,
//   loading: () => null,
// });
const Taboola = dynamic(() => import('../Scripts/Taboola'), {
  ssr: false,
  loading: () => null,
});
const TaboolaPixelContent = dynamic(() => import('../Scripts/TaboolaPixelContent'), {
  ssr: false,
  loading: () => null,
});
const LinkedinPixel = dynamic(() => import('../Scripts/LinkedinPixel'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagLabels = dynamic(() => import('../Scripts/GoogleTagLabels'), {
  ssr: false,
  loading: () => null,
});
const GoogleTagLabelsNoScripts = dynamic(() => import('../Scripts/GoogleTagLabelsNoScripts'), {
  ssr: true,
  loading: () => null,
});
const GeoEdge = dynamic(() => import('../Scripts/GeoEdge'), {
  ssr: true,
  loading: () => null,
});
const CookieConsent = dynamic(() => import('../Scripts/CookieConsent'), {
  ssr: true,
  loading: () => null,
});
const Kilkaya = dynamic(() => import('../Scripts/Kilkaya'), {
  ssr: false,
  loading: () => null,
});

HdcFooter.propTypes = {
  contentId: PropTypes.string.isRequired,
  shouldRenderScripts: PropTypes.bool,
  lineage: PropTypes.arrayOf(PropTypes.shape({})),
};

HdcFooter.defaultProps = {
  shouldRenderScripts: false,
  lineage: null,
};

function Logo(props) {
  return <IconHaaretzComFull {...props} size={4} />;
}
function HdcFooter({ contentId, shouldRenderScripts, lineage, }) {
  const { isBot, } = useIsBot();

  return (
    <Fragment>
      <WebViewExclude>
        <Footer contentId={contentId} logo={Logo} logoAltText="haaretz" />
        <Scripts scriptTag="pushWoosh">
          <PushWoosh site="hdc" />
        </Scripts>
      </WebViewExclude>
      <Scripts scriptTag="chartBeat">
        <ChartBeat shouldRender={shouldRenderScripts} lineage={lineage} />
      </Scripts>
      <Scripts scriptTag="kilkaya">
        <Kilkaya />
      </Scripts>
      <WebViewExclude>
        <Scripts scriptTag="firstImpression">
          <FirstImpression siteId="6956" />
        </Scripts>
      </WebViewExclude>
      <NoSSR>
        <UserDispenser
          render={({ user, }) => (user ? (
            <Scripts scriptTag="outbrainObct">
              <OutbrainObct isPayingUser={user.type === 'paying'} site="hdc" />
            </Scripts>
          ) : null)
          }
        />
      </NoSSR>
      {isBot ? null : (
        <WebViewExclude>
          <Scripts scriptTag="accessListByIp">
            <AccessListByIp />
          </Scripts>
        </WebViewExclude>
      )}
      <Scripts scriptTag="outbrain30sec">
        <Outbrain30Sec site="hdc" />
      </Scripts>
      <WebViewExclude>
        <Scripts scriptTag="pixel">
          <InitPixelFallbackImg site="hdc" />
        </Scripts>
        <Scripts scriptTag="taboolaPixel">
          <Taboola />
        </Scripts>
        <Scripts scriptTag="taboolaPixelContent">
          <TaboolaPixelContent />
        </Scripts>
        <Scripts scriptTag="linkedinPixel">
          <LinkedinPixel />
        </Scripts>
        <Scripts scriptTag="outbrainPixelContent">
          <OutbrainPixelContent />
        </Scripts>
        <Scripts scriptTag="googleAdsPixel">
          <GoogleAdsPixel />
        </Scripts>
        <Scripts scriptTag="googleTagsManager">
          <GoogleTagsManager />
        </Scripts>
        {/* <GaEvents /> */}
        <Scripts scriptTag="facebookPixel">
          <FacebookPixel />
        </Scripts>
        <Scripts scriptTag="twitterPixel">
          <TwitterPixel />
        </Scripts>
      </WebViewExclude>
      <Scripts scriptTag="geoEdge">
        <GeoEdge />
      </Scripts>
      <Scripts scriptTag="outbrianPixel">
        <OutbrainPixel />
      </Scripts>
      <Scripts scriptTag="googleGtag">
        <GoogleTagLabels />
        <GoogleTagLabelsNoScripts />
      </Scripts>
      <Scripts scriptTag="cookieConsent">
        <CookieConsent />
      </Scripts>
    </Fragment>
  );
}

export default HdcFooter;
