// @flow
import React from 'react';

// todo: check if no need for this pixelId
// const pixelId = hostname.includes('themarker.com')
//   ? '288453064669123'
//   : '1465233127023021';

export const facebookPixelId = {
  htz: '801998859871552',
  tm: '335945660336305',
  hdc: '307252476589397',
  commercial: '1438255172883270',
};

export default function InitPixel({ host, }: { host: string, }) {
  const site = host.includes('themarker.com') ? 'tm' : host.includes('haaretz.com') ? 'hdc' : host.includes('haaretz.co.il') ? 'htz' : 'commercial';
  const id = facebookPixelId[site];
  return (
    <React.Fragment>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
              !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${id}');fbq('track', 'PageView');
          `,
        }}
      />
    </React.Fragment>
  );
}
